<template>
    <Panel
        title="Wallet transaction"
        :loading="loading"
        @closePanel="$emit('closePanel', { refresh: refreshAfterClose })"
    >
        <Tabs v-if="transaction">
            <Tab name="Transaction" :selected="true">
                <!-- Data -->
                <Alert
                    v-if="transaction.is_adjustment && transaction.origin_transaction_id"
                    color="info"
                    icon="info-circle"
                    >This transaction cancels
                    <a href="#" @click.prevent="openTransaction(transaction.origin_transaction_id)"
                        >transaction ID {{ transaction.origin_transaction_id }}</a
                    >.</Alert
                >
                <Sheet class="mt-1" name="Details">
                    <Row name="Amount">{{ transaction.amount | currency }}</Row>
                    <Row name="Wallet balance" v-if="transaction.wallet_balance">{{
                        transaction.wallet_balance | currency
                    }}</Row>
                    <Row name="Start date" v-if="transaction.start_date">{{ transaction.start_date | formatDate }}</Row>
                    <Row name="Expiration date" v-if="transaction.expiration_date">{{
                        transaction.expiration_date | formatDate
                    }}</Row>
                    <Row name="Extend expiration date" v-if="transaction.extend_expires_at">{{
                        transaction.extend_expires_at | formatDate
                    }}</Row>
                    <Row name="Main comment" v-if="transaction.main_comment" class="mt-1">
                        <Badge color="info">{{ transaction.main_comment }}</Badge>
                    </Row>
                    <Row
                        name="Comments"
                        v-if="transaction.comments && !Array.isArray(transaction.comments)"
                        class="mt-1"
                    >
                        <RawOutput :item="transaction.comments" />
                    </Row>
                </Sheet>
                <Sheet class="mt-1" name="Request">
                    <Row name="Created date">{{ transaction.created_at | formatDate }}</Row>
                    <Row name="Updated date">{{ transaction.updated_at | formatDate }}</Row>
                    <Row name="Transaction date">{{ transaction.transaction_at | formatDate }}</Row>
                </Sheet>

                <div class="mt-4">
                    <ID name="Hexeko" :value="transaction.id" />
                </div>
            </Tab>
        </Tabs>

        <!-- Actions -->
        <template v-slot:actions v-if="$store.getters.userHasPermission('manageWallets') && !loading && transaction">
            <Dropdown small title="Actions" position="right">
                <DropdownItem
                    confirm
                    @confirm="extend"
                    text="This transaction will be extended"
                    icon="arrows-alt-h"
                    :disabled="disableExtendable"
                    >Extend</DropdownItem
                >
            </Dropdown>
        </template>
    </Panel>
</template>

<script>
    import {
        Alert,
        Alerts,
        Badge,
        Dropdown,
        DropdownItem,
        ID,
        Modal,
        Panel,
        Row,
        Sheet,
        Tab,
        Tabs,
    } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import RawOutput from '@/components/RawOutput'

    export default {
        name: 'WalletTransactionPanel',
        components: {
            Alert,
            Badge,
            Dropdown,
            DropdownItem,
            ID,
            Modal,
            Panel,
            RawOutput,
            Row,
            Sheet,
            Tab,
            Tabs,
        },
        mixins: [Modals],
        props: {
            id: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                loading: true,
                loadingExtend: false,
                transaction: null,
                refreshAfterClose: false,
            }
        },
        computed: {
            disableExtendable() {
                return !this.transaction?.extend_expires_at || this.loadingExtend
            },
        },
        methods: {
            fetchData() {
                this.loading = true
                // Load transaction
                this.$api.walletTransactions
                    .show(this.id)
                    .then((response) => {
                        this.transaction = response
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occurred while trying to load the wallet transaction'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            extend() {
                this.loadingExtend = true
                this.$api.walletTransactions
                    .extend(this.id)
                    .then(() => {
                        this.refreshAfterClose = true
                        Alerts.notificationSuccess('Wallet transaction successfully extended')
                        this.$emit('closePanel', { refresh: this.refreshAfterClose })
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occurred while trying to extend the wallet transaction'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loadingExtend = false
                    })
            },
            openTransaction(id) {
                this.openPanel('WalletTransactionPanel', id)
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
