<template>
    <span v-if="paymentMethod">{{ description }}</span>
</template>

<script>
    export default {
        name: 'PaymentMethodDescription',
        props: {
            paymentMethod: {
                type: Object,
                required: true,
            },
        },
        computed: {
            description() {
                // Bancontact
                if (this.isBancontact) {
                    return 'Bancontact'
                }
                // Credit card
                else if (this.isCreditCard) {
                    return '**** **** **** ' + this.paymentMethod.last_four
                }
                // Wallet with source
                else if (this.paymentMethod.source && this.paymentMethod.source.source) {
                    return this.$t('paymentMethod.walletSource', {
                        source: this.paymentMethod.source.source.name,
                    })
                } else {
                    return this.$t('paymentMethod.wallet')
                }
            },
            isBancontact() {
                return this.paymentMethod.brand === 'bancontact'
            },
            isCreditCard() {
                return !!(this.paymentMethod.brand && this.paymentMethod.brand !== 'bancontact')
            },
        },
    }
</script>
