<template>
    <Panel title="Card" :loading="loading" @closePanel="closePanel">
        <Tabs v-if="card">
            <Tab name="Card" :selected="true">
                <div class="mb-2">
                    <Badge icon="credit-card" class="text-capitalize me-1">{{ card.type }}</Badge>
                    <CardStatus :card="card" />
                </div>

                <Stat :value="card.allowed_balance" legend="Allowed balance" money decimals class="mb-2" />

                <Alert v-if="card.wallets.length === 0" icon="exclamation-triangle"
                    >No wallets linked to this card
                </Alert>

                <Sheet name="Card">
                    <Row name="Currency">{{ card.currency }}</Row>
                    <Row name="Expiry">{{ card.expiry }}</Row>
                    <Row name="Last four">•••• {{ card.last_four }}</Row>
                    <Row name="IBAN for deposit"
                        >{{ card.iban_for_deposit }}
                        <a href="#" v-clipboard:copy="card.iban_for_deposit"> <Icon icon="copy" /> </a
                    ></Row>
                    <Row name="Issued by" v-if="card.issuer"
                        ><a href="#" @click.prevent="openGroup(card.issuer)"
                            ><Icon icon="building" /> {{ card.issuer.name }}</a
                        ></Row
                    >
                    <Row v-if="card.brand" name="Card brand">
                        <Brand :slug="card.brand" />
                    </Row>
                    <Row name="Wallets eligible">{{ card.wallets.length }}</Row>
                    <Row name="Created at">{{ card.created_at | formatDate }}</Row>
                    <Row name="Updated at">{{ card.updated_at | formatDate }}</Row>
                    <Row name="Deleted at" v-if="card.deleted_at">{{ card.deleted_at | formatDate }}</Row>
                </Sheet>

                <Sheet name="Cardholder">
                    <Row name="User" v-if="card.user"
                        ><a href="#" @click.prevent="openUser(card.user)">
                            <Icon icon="user" />
                            {{ card.user.name }}</a
                        ></Row
                    >
                    <Row name="Name on card">{{ card.name_on_card }}</Row>
                    <Row name="Holder mobile">{{ card.card_holder_mobile }}</Row>
                </Sheet>

                <Sheet name="PIN" v-if="card.pin">
                    <Row name="Online PIN">
                        <CardPinStatus :status="card.pin.online.status" :large="false" />
                    </Row>
                    <Row name="Offline PIN">
                        <CardPinStatus :status="card.pin.offline.status" :large="false" />
                    </Row>
                </Sheet>

                <Sheet name="Delivery" v-if="card.type === 'physical' && card.shipping && card.shipping.address">
                    <Row v-if="card.shipping.address.delivery_service" name="Delivery service"
                        ><span class="text-capitalize">{{ card.shipping.address.delivery_service }}</span
                        ><span v-if="!!deliveryServiceTime"> ({{ deliveryServiceTime }})</span></Row
                    >
                    <Row name="Estimated date">{{ card.shipping.eta | formatDate('DD/MM/YYYY') }}</Row>
                    <Row name="Address">
                        <Address :address="card.shipping.address">
                            <Row v-if="card.shipping.address.recipient_name" name="Recipient name">{{
                                card.shipping.address.recipient_name
                            }}</Row>
                        </Address>
                    </Row>
                    <Row name="Status">{{ card.shipping.status }}</Row>
                </Sheet>
            </Tab>
            <Tab
                name="Authorizations"
                v-if="
                    $store.getters.userHasAnyPermission(['showPaymentTransactions', 'managePaymentTransactions']) &&
                    !card.canceled
                "
            >
                <CardAuthorizations :params="{ card: card.id }" />
            </Tab>
            <Tab name="Transactions" v-if="!card.canceled">
                <CardTransactions :params="{ card: card.id }" />
            </Tab>
            <Tab
                name="Linked wallets"
                v-if="
                    $store.getters.userHasAnyPermission(['showWallets', 'manageWallets']) && card.user && !card.canceled
                "
            >
                <LinkedWallets :card="card.id" :user="card.user.id" />
            </Tab>
            <Tab name="Tokens" v-if="$store.getters.userHasPermission('manageCards')">
                <CardTokens :card="card.id" />
            </Tab>
        </Tabs>

        <!-- Footer -->
        <div v-if="card" class="mt-4">
            <ID name="Hexeko" :value="card.id" />
            <ID
                name="Stripe"
                :url="`https://dashboard.stripe.com/${card.issuer.external_id.stripe}/issuing/cards/${card.external_id}`"
                :value="card.external_id"
                class="ms-3"
            />
        </div>

        <template v-slot:actions v-if="card && $store.getters.userHasPermission('manageCards')">
            <!-- Actions -->
            <Dropdown small title="Actions" position="right" v-if="card && !card.canceled">
                <DropdownItem
                    confirm
                    @confirm="freeze()"
                    text="Card will be unavailable for use"
                    icon="pause-circle"
                    :disabled="card.freezed"
                >
                    Freeze card
                </DropdownItem>
                <DropdownItem
                    confirm
                    @confirm="unfreeze()"
                    text="Card will be again available for use"
                    icon="play-circle"
                    :disabled="!card.freezed"
                >
                    Unfreeze card
                </DropdownItem>
                <DropdownItem
                    @click="openModal('stopCard')"
                    text="Card will be cancelled definitively"
                    icon="stop-circle"
                >
                    Stop card
                </DropdownItem>
                <DropdownItem @click="openModal('replaceCard')" icon="sync">Replace card</DropdownItem>
                <DropdownItem @click="openModal('updatePinCard')" icon="lock" v-if="card.type == 'physical'">
                    Update PIN
                </DropdownItem>
            </Dropdown>

            <!-- Replace card -->
            <Modal v-if="isModalOpen('replaceCard')" @close="closeModal('replaceCard')" title="Replace card">
                <ReplaceCard :card="card.id" :type="card.type" @submitted="cardReplaced" />
            </Modal>

            <Modal v-if="isModalOpen('updatePinCard')" @close="closeModal('updatePinCard')" title="Update PIN">
                <UpdatePinCard :id="card.id" @submitted="pinUpdated" />
            </Modal>

            <Modal v-if="isModalOpen('stopCard')" @close="closeModal('stopCard')" title="Stop card">
                <StopCard :cardId="card.id" @submitted="cardStopped" />
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import {
        Panel,
        Tabs,
        Tab,
        Sheet,
        Row,
        ID,
        Dropdown,
        DropdownItem,
        Alerts,
        Badge,
        Stat,
        Alert,
        Modal,
    } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import CardAuthorizations from '../CardAuthorizations/index'
    import CardTransactions from '../CardTransactions/index'
    import LinkedWallets from './LinkedWallets'
    import ReplaceCard from './ReplaceCard'
    import UpdatePinCard from './UpdatePinCard'
    import CardStatus from '@/components/Cards/status.vue'
    import CardPinStatus from '@/components/Cards/PinStatus.vue'
    import StopCard from './StopCard.vue'
    import Address from '@/components/Address'
    import CardTokens from '@/components/Cards/CardTokens.vue'
    import Brand from '@/components/Brand'

    export default {
        name: 'CardPanel',
        mixins: [Modals],
        components: {
            Address,
            Panel,
            Tabs,
            Tab,
            Sheet,
            Row,
            ID,
            Dropdown,
            DropdownItem,
            CardAuthorizations,
            CardTransactions,
            Badge,
            Stat,
            Alert,
            LinkedWallets,
            Modal,
            ReplaceCard,
            UpdatePinCard,
            CardStatus,
            CardPinStatus,
            StopCard,
            CardTokens,
            Brand,
        },
        data() {
            return {
                loading: true,
                card: null,
                isUpdated: false,
            }
        },
        props: {
            id: String,
        },
        computed: {
            deliveryServiceTime() {
                if (
                    this.card &&
                    this.card.shipping &&
                    this.card.shipping.address &&
                    this.$te(`delivery.${this.card.shipping.address.delivery_service}`)
                ) {
                    return this.$t(`delivery.${this.card.shipping.address.delivery_service}`)
                }
                return null
            },
        },
        methods: {
            fetchData() {
                this.$api.cards
                    .show(this.id, {
                        includes: [
                            'issuer',
                            'user',
                            'card_holder_mobile',
                            'shipping',
                            'allowed_balance',
                            'iban_for_deposit',
                            'wallets',
                            'pin',
                            'issuer.external_id',
                        ],
                    })
                    .then((response) => {
                        this.card = response
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                        this.closePanel()
                    })
            },
            freeze() {
                this.$api.cards.freeze(this.id).then(() => {
                    Alerts.messageSuccess('Card freezed')
                    this.fetchData()
                    this.isUpdated = true
                })
            },
            unfreeze() {
                this.$api.cards.unfreeze(this.id).then(() => {
                    Alerts.messageSuccess('Card unfreezed')
                    this.fetchData()
                    this.isUpdated = true
                })
            },
            cancel() {
                this.$api.cards.stop(this.id).then(() => {
                    Alerts.messageSuccess('Card canceled')
                    this.isUpdated = true
                    this.closePanel()
                })
            },
            cardReplaced() {
                this.closeModal('replaceCard')
                this.isUpdated = true
                this.closePanel()
            },
            cardStopped() {
                this.closeModal('stopCard')
                this.isUpdated = true
                this.closePanel()
            },
            pinUpdated() {
                this.closeModal('updatePinCard')
            },
            closePanel(args) {
                if (this.isUpdated) {
                    args = { refresh: true }
                }
                this.$emit('closePanel', args)
            },
            openGroup(group) {
                this.openPanel('GroupPanel', group.id)
            },
            openUser(user) {
                this.openPanel('UserPanel', user.id)
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
