<template>
    <Datatable
        name="Expense Requests"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
        scroll-x
    >
        <template v-slot:filters>
            <Button
                class="me-1"
                @click="filterByStatus(avStatus.status)"
                :color="status === avStatus.status ? avStatus.color : 'secondary'"
                small
                v-for="(avStatus, index) in statuses"
                :key="index"
            >
                {{ avStatus.name }}
            </Button>
            <Dropdown v-if="getStores.length > 0" class="mb-1" color="secondary" title="Filter by store" small>
                <li v-for="(store, index) in getStores" :key="index">
                    <a
                        href="#"
                        class="dropdown-item text-xs"
                        :class="storeSelected == store.id ? 'bg-primary text-white' : 'bg-white'"
                        @click.prevent="filterByStore(store.id)"
                        >{{ store.name }}</a
                    >
                </li>
            </Dropdown>
        </template>
        <template v-slot:thead>
            <tr>
                <th>ID</th>
                <th>Last update</th>
                <th v-if="!isGroupTab">Store</th>
                <th>User</th>
                <th>Group</th>
                <th>Partner</th>
                <th>Amount</th>
                <th>Status</th>
                <th></th>
            </tr>
        </template>
        <template v-slot:actions v-if="$store.getters.userHasPermission('manageExpenseRequests')">
            <Button small @click="openModal('createExpenseRequest')" icon="plus">Create request</Button>
            <Modal
                v-if="isModalOpen('createExpenseRequest')"
                @close="closeModal('createExpenseRequest')"
                title="Create expense request"
            >
                <Update :values="params" @submitted="expenseRequestSubmitted" />
            </Modal>
        </template>
        <template v-slot:default>
            <tr v-for="(request, index) in expenseRequests" @click="open(request)" :key="index">
                <td>
                    <code>{{ request.id }}</code>
                </td>
                <td>{{ request.updated_at | formatDate }}</td>
                <td v-if="!isGroupTab">
                    <div v-if="request.user"><Store :id="request.user.store_id" /></div>
                </td>
                <td>
                    <div v-if="request.user">{{ request.user.name }}</div>
                </td>
                <td>
                    {{ request && request.group && request.group.name ? request.group.name : 'N/A' }}
                </td>
                <td>
                    <Badge v-if="request.partner" color="success" icon="handshake">{{
                        request.partner.name[$store.state.lang.displayLanguage]
                    }}</Badge>
                    <Badge v-else color="secondary" icon="question-circle">Partner unknown</Badge>
                </td>
                <td>{{ request.amount | currency }}</td>
                <td><ExpenseRequestStatus :status="request.status"></ExpenseRequestStatus></td>
                <td class="text-right"><Icon icon="angle-right" /></td>
            </tr>
        </template>
    </Datatable>
</template>

<script>
    import { Datatable, Badge, Button, Boolean, Modal, Dropdown } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import ExpenseRequestStatus from '@/components/ExpenseRequests/status'
    import Update from '@/components/ExpenseRequests/Update'
    import Store from '@/components/layout/Store'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'

    export default {
        name: 'ExpenseRequests',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                search: null,
                status: null,
                pagination: {
                    current_page: 1,
                },
                expenseRequests: [],
                statuses: [
                    {
                        name: 'Pending',
                        status: 'pending',
                        color: 'warning',
                    },
                    {
                        name: 'Waiting rectification',
                        status: 'need_rectification',
                        color: 'dark',
                    },
                    {
                        name: 'Confirmed',
                        status: 'confirmed',
                        color: 'success',
                    },
                    {
                        name: 'Declined',
                        status: 'declined',
                        color: 'danger',
                    },
                    {
                        name: 'Cancelled',
                        status: 'cancelled',
                        color: 'danger',
                    },
                    {
                        name: 'Debit pending',
                        status: 'debit_pending',
                        color: 'warning',
                    },
                ],
                storeSelected: null,
            }
        },
        components: {
            Datatable,
            Badge,
            ExpenseRequestStatus,
            Button,
            Modal,
            Update,
            Store,
            Dropdown,
        },
        mixins: [Modals],
        mounted() {
            this.fetchData()
        },
        methods: {
            async fetchData(search) {
                this.search = search
                this.loading = true
                let params = this.mergeParams({
                    search: this.search,
                    statuses: [this.status],
                    page: this.pagination.current_page,
                    order_by: 'created_at',
                    sort_by: 'desc',
                    store_id: this.storeSelected ? this.storeSelected : undefined,
                    includes: ['partner', 'user', 'group'],
                })
                const response = await this.getRequest(params)
                this.expenseRequests = response.data
                this.pagination = response.meta.pagination
                this.loading = false
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(expenseRequest) {
                let panel = this.openPanel('ExpenseRequestPanel', expenseRequest.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            filterByStatus(status) {
                this.status = this.status === status ? null : status
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            filterByStore(id) {
                if (this.storeSelected === id) {
                    this.storeSelected = null
                } else if (this.storeSelected !== id) {
                    this.storeSelected = id
                }
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            expenseRequestSubmitted(expense) {
                this.openPanel('ExpenseRequestPanel', expense.id)
                this.closeModal('createExpenseRequest')
            },
            getRequest(params) {
                if (this.isGroupTab) {
                    const { groupId, ...otherParams } = params
                    return this.$api.groups.getExpenseRequests(groupId, otherParams)
                }
                return this.$api.expenseRequests.get(params)
            },
        },
        computed: {
            getStores() {
                return this.$store.getters.getStores
            },
            isGroupTab() {
                return Object.keys(this.params).includes('groupId')
            },
        },
    }
</script>
