<template>
    <div>
        <!-- Loading -->
        <template v-if="loading">
            <SkeletonPaymentMethod />
            <SkeletonPaymentMethod />
        </template>

        <template v-else-if="displayed_payment_methods">
            <!-- No payment methods -->
            <template v-if="displayed_payment_methods.length === 0">
                <Alert icon="info-circle" color="secondary">{{ $t('paymentMethod.empty') }}</Alert>
            </template>

            <!-- List payment methods -->
            <template v-else>
                <div class="mb-2 text-xxs text-muted" v-if="is_selector">
                    {{ $t('paymentMethod.amount') }} : {{ total_selected }} / {{ amount }}&nbsp;&euro;
                </div>
                <div
                    class="payment-method-item"
                    @click="handleMethodClick(payment_method)"
                    v-for="(payment_method, index) in displayed_payment_methods"
                    :key="index"
                >
                    <PaymentMethodWallet
                        v-if="payment_method.driver === 'wallet'"
                        :wallet="payment_method"
                        :class="dynamicClass(payment_method)"
                        :useBalance="useBalance"
                    />
                    <PaymentMethod
                        v-else
                        :paymentMethod="payment_method"
                        :class="dynamicClass(payment_method)"
                        @deleted="cardDeleted"
                        :hideActions="hideActions"
                    />
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import { Alert } from '@tech_hexeko/design-system'
    import PaymentMethodWallet from './Wallet'
    import SkeletonPaymentMethod from '@tech_hexeko/hexeko-user-account/src/components/PaymentMethods/SkeletonPaymentMethodItem'
    import PaymentMethod from './PaymentMethod'
    import moment from 'moment'
    let _ = require('lodash')

    export default {
        name: 'PaymentMethodsList',
        components: {
            Alert,
            PaymentMethod,
            PaymentMethodWallet,
            SkeletonPaymentMethod,
        },
        props: {
            amount: {
                type: Number,
                default: 0,
            },
            date: {
                type: String,
                default: null,
            },
            hideActions: {
                type: Boolean,
                default: false,
            },
            paymentMethods: Array,
            paymentMethodsFilter: Function,
            showWithoutBalance: Boolean,
            useBalance: {
                type: Boolean,
                default: false,
            },
            user: Number,
            value: {
                type: Array,
                default: () => {
                    return []
                },
            },
        },
        data() {
            return {
                loading: false,
                fetched_payment_methods: [],
                selected_payment_methods: [],
            }
        },
        computed: {
            total_selected() {
                return _.round(_.sumBy(this.selected_payment_methods, 'amount'), 2)
            },
            remaining_amount_to_select() {
                return _.round(this.amount - this.total_selected, 2)
            },
            completed() {
                return this.remaining_amount_to_select === 0
            },
            displayed_payment_methods() {
                let payment_methods = this.paymentMethods ? this.paymentMethods : this.fetched_payment_methods
                payment_methods = payment_methods.filter((paymentMethod) => {
                    let payment_balance =
                        this.isWalletDriver(paymentMethod) && !this.useBalance
                            ? paymentMethod.amount_selectable
                            : paymentMethod.balance
                    let filterCondition = payment_balance !== 0 || this.showWithoutBalance
                    if (this.isWalletDriver(paymentMethod))
                        return filterCondition && paymentMethod.payment_authorized_by_supplier !== false
                    return filterCondition
                })
                if (this.paymentMethodsFilter) {
                    payment_methods = payment_methods.filter((payment_method) => {
                        return this.paymentMethodsFilter(payment_method)
                    })
                }
                return payment_methods
            },
            total_payment_methods_balance() {
                let label = this.useBalance ? 'balance' : 'amount_selectable'
                return _.sumBy(this.displayed_payment_methods, label)
            },
            is_selector() {
                return !!this.amount
            },
            has_click_listener() {
                return this.$listeners && this.$listeners.click
            },
            isDateValid() {
                return this.date && moment(this.date).isValid()
            },
        },
        methods: {
            fetchPaymentMethods: _.debounce(function () {
                let params = {
                    isCancellable: true,
                    includes: [
                        'payment_methods.source.types.slugs',
                        'payment_methods.source.balances',
                        'payment_methods.source.spendable_amount',
                        'payment_methods.source.total_deposit',
                    ],
                    ...(this.isDateValid && { transaction_at: moment(this.date).format('YYYY-MM-DD HH:mm:ss') }),
                }

                if (this.paymentMethods) {
                    return true
                } else if (this.user) {
                    this.loading = true
                    this.$api.users
                        .getPaymentMethods(this.user, params)
                        .then((response) => {
                            this.fetched_payment_methods = response.payment_methods
                            this.loading = false
                            this.emit()
                        })
                        .catch((error) => {
                            if (error && error.response && error.response.data)
                                console.warn(error.response.data.message)
                        })
                } else {
                    this.loading = true
                    this.$api.me
                        .getPaymentMethods(params)
                        .then((response) => {
                            this.fetched_payment_methods = response.payment_methods
                            this.loading = false
                            this.emit()
                        })
                        .catch((error) => {
                            if (error && error.response && error.response.data)
                                console.warn(error.response.data.message)
                        })
                }
            }, 2000),
            isSelected(payment_method) {
                return (
                    _(this.selected_payment_methods)
                        .filter((selected) => {
                            return selected.id === payment_method.id
                        })
                        .size() > 0
                )
            },
            toggleSelect(payment_method) {
                if (this.isSelected(payment_method)) {
                    this.unselect(payment_method)
                } else {
                    this.select(payment_method)
                }
            },
            unselect(payment_method) {
                this.selected_payment_methods = _.filter(this.selected_payment_methods, (selected) => {
                    return selected.id !== payment_method.id
                })
                this.emit()
            },
            select(payment_method) {
                // Check that payment method is clickable
                if (!this.clickablePaymentMethod(payment_method)) return false

                // Check that maximum amount is not reached
                if (this.completed) {
                    this.clear()
                    return this.select(payment_method)
                }

                // Calculate amount
                const payment_balance =
                    this.isWalletDriver(payment_method) && !this.useBalance
                        ? payment_method.amount_selectable
                        : payment_method.balance
                let amount = payment_balance !== null ? payment_balance : this.remaining_amount_to_select
                if (amount > this.remaining_amount_to_select) amount = this.remaining_amount_to_select

                // Add payment method
                this.selected_payment_methods.push({
                    id: payment_method.id,
                    amount: amount,
                })

                this.emit()
            },
            emit() {
                this.$emit('input', this.selected_payment_methods)
                this.$emit('completed', this.completed)
                if (this.remaining_amount_to_select) {
                    this.$emit('error', `${this.$t('paymentMethod.stillSelect')} : ${this.remaining_amount_to_select}€`)
                }
            },
            clear() {
                this.selected_payment_methods = []
                this.emit()
            },
            clickablePaymentMethod(payment_method) {
                const payment_balance =
                    this.isWalletDriver(payment_method) && !this.useBalance
                        ? payment_method.amount_selectable
                        : payment_method.balance
                return (
                    this.isAvailable(payment_method) &&
                    ((this.is_selector && payment_balance !== 0) || this.has_click_listener)
                )
            },
            disabledPaymentMethod(payment_method) {
                return this.is_selector && !this.clickablePaymentMethod(payment_method)
            },
            isAvailable(payment_method) {
                return (
                    !payment_method.availability || (payment_method.availability && payment_method.availability.status)
                )
            },
            cardDeleted() {
                this.$emit('creditCardDeleted')
                this.fetchPaymentMethods()
            },
            handleMethodClick(payment_method) {
                this.$emit('click', payment_method)
                if (this.is_selector) this.toggleSelect(payment_method)
            },
            dynamicClass(payment_method) {
                return {
                    'border-primary': this.isSelected(payment_method),
                    'pointer-events-none opacity-5': this.disabledPaymentMethod(payment_method),
                    'cursor-pointer': this.clickablePaymentMethod(payment_method),
                }
            },
            isWalletDriver(payment_method) {
                return payment_method.driver && payment_method.driver === 'wallet'
            },
        },
        watch: {
            user() {
                this.clear()
                this.fetchPaymentMethods()
            },
            amount(val, oldVal) {
                if (val != oldVal) this.clear()
            },
            date: {
                immediate: true,
                handler() {
                    this.fetchPaymentMethods()
                },
            },
            value: {
                deep: true,
                immediate: true,
                handler() {
                    this.selected_payment_methods = this.value
                },
            },
            total_payment_methods_balance(val) {
                this.$emit('availableBalance', val)
            },
        },
    }
</script>
