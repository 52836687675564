<template>
    <Panel title="Booking" :loading="loading" @closePanel="$emit('closePanel')">
        <Tabs v-if="booking">
            <!-- Booking -->
            <Tab name="Booking" :selected="tab === 'booking'">
                <Tabs :pills="false" :small="true">
                    <!-- Booking -->
                    <Tab name="Booking" :selected="true">
                        <BookingStatus large class="mb-2" :status="booking.status"></BookingStatus>
                        <Sheet>
                            <Row name="Activity" v-if="booking.product && booking.product.activity"
                                ><a href="#" @click.prevent="openActivity(booking.product.activity)">{{
                                    booking.product.activity.name[$store.state.lang.displayLanguage]
                                }}</a></Row
                            >
                            <Row name="Offer" v-if="booking.product">
                                <a v-if="offerExists" href="#" @click.prevent="openOffer(booking.product)">{{
                                    booking.product.name[$store.state.lang.displayLanguage]
                                }}</a>
                                <template v-else
                                    >{{ booking.product.name[$store.state.lang.displayLanguage] }} (no longer
                                    exists)</template
                                >
                            </Row>
                            <Row name="Partner" v-if="partners.length > 0">
                                <div v-for="partner in partners">
                                    <a href="#" @click.prevent="openPartner(partner)">{{
                                        partner.name[$store.state.lang.displayLanguage]
                                    }}</a>
                                </div>
                            </Row>
                            <Row name="Time slots" v-if="booking.time_slots.length > 0">
                                <div v-for="time_slot in booking.time_slots">
                                    {{ $options.filters.dateRangeHumanReadable(time_slot.start, time_slot.end) }}
                                </div>
                            </Row>
                            <Row name="Amount">{{ booking.amount | currency }}</Row>
                            <Row name="User" v-if="booking.booker">
                                <div>
                                    <a href="#" @click.prevent="openUser(booking.booker)">{{ booking.booker.name }}</a>
                                </div>
                                <div>{{ booking.booker.email }}</div>
                                <div>{{ booking.booker.birthdate | formatDate('DD/MM/YYYY') }}</div>
                            </Row>
                            <Row name="Cancellation">
                                <Boolean
                                    v-model="booking.is_cancellable.status"
                                    :textOn="booking.is_cancellable.reason"
                                    :textOff="booking.is_cancellable.reason"
                                ></Boolean>
                            </Row>
                            <Row name="Created at">{{ booking.created_at | formatDate }}</Row>
                            <Row name="Updated at">{{ booking.updated_at | formatDate }}</Row>
                        </Sheet>
                        <ID name="Hexeko" :value="booking.id" />
                    </Tab>

                    <!-- History -->
                    <Tab name="History">
                        <History :statuses="booking.statuses" v-slot:default="slotProps">
                            <BookingStatus :status="slotProps.status.name" />
                        </History>
                    </Tab>
                </Tabs>
            </Tab>

            <!-- Booking request -->
            <Tab name="Request" :selected="tab === 'request'">
                <Request :id="booking.booking_request.id"></Request>
            </Tab>

            <!-- Voucher -->
            <Tab
                name="Voucher"
                v-if="booking.voucher && $store.getters.userHasAnyPermission(['showVouchers', 'manageVouchers'])"
                :selected="tab === 'voucher'"
            >
                <Sheet>
                    <Row name="Voucher ID"
                        ><a href="#" @click.prevent="openVoucher(booking.voucher.id)"
                            ># {{ booking.voucher.id }}</a
                        ></Row
                    >
                    <Row name="Picked at">{{ booking.voucher.picked_at | formatDate }}</Row>
                    <Row name="PDF"
                        ><a href="#" @click.prevent="downloadVoucher(booking.voucher.id)"
                            ><Icon icon="file-pdf" /> Voucher #{{ booking.voucher.id }}</a
                        ></Row
                    >
                </Sheet>
            </Tab>
        </Tabs>

        <template v-slot:actions v-if="booking && $store.getters.userHasPermission('manageBookings')">
            <Dropdown small title="Actions" position="right">
                <template v-if="booking.status === 'pending'">
                    <DropdownItem class="text-success" confirm @confirm="confirmBooking" icon="check-circle"
                        >Confirm</DropdownItem
                    >
                    <DropdownItem class="text-danger" confirm @confirm="declineBooking" icon="stop-circle"
                        >Decline</DropdownItem
                    >
                </template>
                <DropdownItem
                    confirm
                    text="This booking will be cancelled and refunded"
                    @confirm="cancelBooking"
                    icon="times-circle"
                    >Cancel and refund this booking</DropdownItem
                >
                <DropdownItem
                    confirm
                    text="All bookings created by this booking request will be cancelled and refunded ?"
                    @confirm="refundBookingRequest"
                    icon="undo-alt"
                    >Cancel and refund all bookings</DropdownItem
                >
            </Dropdown>
        </template>
    </Panel>
</template>

<script>
    import {
        Panel,
        Sheet,
        Row,
        Boolean,
        ID,
        Alerts,
        Dropdown,
        DropdownItem,
        Tabs,
        Tab,
        Table,
        Button,
    } from '@tech_hexeko/design-system'
    import BookingStatus from '@/components/Bookings/status'
    import Availability from '@/components/layout/Availability'
    import moment from 'moment'
    import Request from './Request'
    import History from '@/components/layout/History'
    export default {
        name: 'BookingPanel',
        props: {
            id: Number,
            tab: {
                type: String,
                default: 'booking',
            },
        },
        data() {
            return {
                loading: true,
                booking: null,
                booking_request: {},
                partners: [],
                moment: moment,
                offerExists: true,
            }
        },
        components: {
            Panel,
            Sheet,
            Row,
            Boolean,
            BookingStatus,
            ID,
            Dropdown,
            DropdownItem,
            Tabs,
            Tab,
            Availability,
            Table,
            Request,
            Button,
            History,
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true

                // Load booking
                this.$api.bookings
                    .show(this.id, {
                        includes: ['statuses', 'voucher'],
                    })
                    .then((booking) => {
                        this.booking = booking
                        // Load offer
                        if (this.booking.product) {
                            this.$api.offers
                                .show(this.booking.product.id)
                                .then((offer) => {
                                    // Load partners
                                    this.$api.activities
                                        .getPartners(offer.activity_id, {
                                            includes: ['medias', 'description', 'opening_hours'],
                                        })
                                        .then((response) => {
                                            this.partners = response.data
                                        })
                                        .finally(() => {
                                            this.loading = false
                                        })
                                })
                                .catch(() => {
                                    this.offerExists = false
                                    this.loading = false
                                })
                        }
                    })
            },
            openUser(user) {
                this.openPanel('userPanel', user.id)
            },
            openBookingRequest(request) {
                this.openPanel('bookingRequestPanel', request.id)
            },
            openPartner(partner) {
                this.openPanel('partnerPanel', partner.id)
            },
            openActivity(activity) {
                this.openPanel('activityPanel', activity.id)
            },
            openOffer(offer) {
                this.openPanel('offerPanel', offer.id)
            },
            openWallet(id) {
                this.openPanel('walletPanel', id)
            },
            openVoucher(id) {
                this.openPanel('voucherPanel', id)
            },
            confirmBooking() {
                this.$api.bookings.confirm(this.id).then((response) => {
                    Alerts.notificationSuccess('Booking confirmed')
                    this.fetchData()
                })
            },
            declineBooking() {
                this.$api.bookings.decline(this.id).then((response) => {
                    Alerts.notificationSuccess('Booking declined')
                    this.fetchData()
                })
            },
            cancelBooking() {
                this.$api.bookings.cancel(this.id).then((response) => {
                    Alerts.notificationSuccess('Booking cancelled')
                    this.$emit('closePanel', { refresh: true, cancelled: true })
                })
            },
            refundBookingRequest() {
                this.$api.bookings.refundBookingRequest(this.booking.booking_request.id).then((response) => {
                    Alerts.notificationSuccess('Booking request refunded')
                    this.$emit('closePanel', { refresh: true, cancelled: true })
                })
            },
            downloadVoucher(id) {
                this.$api.vouchers.temporaryUrl(id).then((response) => {
                    window.open(response.url, '_blank')
                })
            },
            hasStatus(statuses) {
                statuses.forEach((status) => {
                    if (!this.findStatus(status)) {
                        return false
                    }
                })
                return true
            },
            findStatus(status) {
                return this.booking_request.statuses.find((brStatus) => brStatus.status === status)
            },
            statusDate(status) {
                let foundStatus = this.findStatus(status)
                return foundStatus ? foundStatus.created_at : null
            },
        },
    }
</script>
