<template>
    <div>
        <Booking v-if="booking" :id="booking.id" tab="request" @closePanel="$emit('closePanel')" />
        <Panel v-else title="Booking request" :loading="loading" @closePanel="$emit('closePanel')">
            <Request :id="id" />
        </Panel>
    </div>
</template>

<script>
    import { Panel } from '@tech_hexeko/design-system'
    import Booking from '@/components/Bookings/show'
    import Request from '@/components/Bookings/Request'

    export default {
        name: 'BookingRequestPanel',
        props: {
            id: String,
        },
        data() {
            return {
                loading: true,
                booking: null,
            }
        },
        components: {
            Panel,
            Booking,
            Request,
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true
                // Load booking
                this.$api.bookings.showBookingRequest(this.id).then((request) => {
                    this.booking = request.bookings[0]
                    this.loading = false
                })
            },
        },
    }
</script>
