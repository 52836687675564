<template>
    <Panel title="Voucher list" :loading="loading" @closePanel="closePanel">
        <Tabs v-if="list">
            <Tab name="Vouchers" :selected="true">
                <Progress :total="list.count_vouchers" :count="list.count_vouchers_picked" class="mb-2"></Progress>
                <Vouchers :params="{ list: list.id }"></Vouchers>
            </Tab>
            <Tab name="List">
                <Sheet>
                    <Row name="Name">{{ list.name }}</Row>
                    <Row name="Created at">{{ list.created_at | formatDate }}</Row>
                    <Row name="Updated at">{{ list.updated_at | formatDate }}</Row>
                </Sheet>
                <ID name="Hexeko" :value="list.id" class="me-1" />
                <template v-if="$store.getters.userHasPermission('manageVouchers')">
                    <Button color="danger" is="confirm" @confirm="deleteList" title="Are you sure you want to delete?"
                        ><Icon icon="trash-alt" /> Delete</Button
                    >
                </template>
            </Tab>
        </Tabs>

        <template v-slot:actions v-if="list && $store.getters.userHasPermission('manageVouchers')">
            <!-- Actions -->
            <Dropdown small title="Actions" position="right">
                <DropdownItem @click="openModal('updateList')" icon="edit">Update list</DropdownItem>
                <DropdownItem
                    confirm
                    @confirm="sendMissing()"
                    text="All vouchers that were missing on previous booking will be generated and sent to users who didn't received their vouchers yet."
                    icon="paper-plane"
                    >Send missing</DropdownItem
                >
            </Dropdown>
            <!-- Update offer -->
            <Modal v-if="isModalOpen('updateList')" @close="closeModal('updateList')" title="Update list">
                <UpdateList :id="list.id" @submitted="listUpdated"></UpdateList>
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import {
        Panel,
        Tabs,
        Tab,
        Sheet,
        Row,
        ID,
        Dropdown,
        DropdownItem,
        Alerts,
        Progress,
        Button,
        Modal,
    } from '@tech_hexeko/design-system'
    import Vouchers from '@/components/Vouchers'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import UpdateList from './UpdateList'

    export default {
        name: 'VoucherPanel',
        mixins: [Modals],
        components: {
            Panel,
            Tabs,
            Tab,
            Sheet,
            Row,
            ID,
            Dropdown,
            Vouchers,
            Progress,
            Button,
            UpdateList,
            DropdownItem,
            Modal,
        },
        props: {
            id: Number,
        },
        data() {
            return {
                loading: true,
                list: null,
            }
        },
        methods: {
            fetchData() {
                this.loading = true
                this.$api.voucherLists.show(this.id).then((response) => {
                    // set data
                    this.list = response
                    // stop loading
                    this.loading = false
                })
            },
            deleteList() {
                this.$api.voucherLists.delete(this.id).then(() => {
                    Alerts.notificationSuccess('Successfully deleted')
                    this.closePanel({ refresh: true })
                })
            },
            closePanel(args) {
                this.$emit('closePanel', args)
            },
            listUpdated() {
                this.closeModal('updateList')
                this.fetchData()
            },
            sendMissing() {
                this.$api.voucherLists.sendMissing(this.id).then(() => {
                    Alerts.notificationSuccess('Successfully send to missing')
                })
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
