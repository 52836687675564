<template>
    <WalletIcons
        v-if="
            paymentMethod.driver === 'wallet' &&
            paymentMethod.source &&
            paymentMethod.source.types &&
            paymentMethod.source.types.length > 0
        "
        position="left"
        :small="small"
        :wallet="paymentMethod.source"
    />
    <component v-else-if="icon" :is="`icon-${icon}`" :width="size" :height="size" />
</template>

<script>
    import {
        IconAmex,
        IconBancontact,
        IconMasterCard,
        IconMonizzeECV,
        IconMonizzeGift,
        IconVisa,
    } from '@tech_hexeko/design-system/src/components/Icons'
    import WalletIcons from '@tech_hexeko/hexeko-user-account/src/components/WalletIcons'

    export default {
        name: 'PaymentMethodIcon',
        components: {
            IconBancontact,
            IconMastercard: IconMasterCard,
            'icon-ecv': IconMonizzeECV,
            'icon-gift': IconMonizzeGift,
            IconVisa,
            IconAmex,
            WalletIcons,
        },
        props: {
            paymentMethod: {
                type: Object,
                required: true,
            },
            small: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            icon() {
                if (this.paymentMethod.brand) {
                    return this.paymentMethod.brand
                } else if (this.paymentMethod.driver === 'monizze') {
                    return this.paymentMethod.identifier
                }
                return null
            },
            size() {
                return this.small ? '25px' : '50px'
            },
        },
    }
</script>
