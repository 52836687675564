<template>
    <div
        v-if="paymentMethod.payment_method"
        class="payment-method-summary clearfix"
        :class="{ 'cursor-pointer': clickable }"
        @click="click(paymentMethod.payment_method)"
    >
        <div class="row">
            <div class="col-12 pe-0 mb-1 mb-sm-0" :class="smallIcons ? 'col-sm-2' : 'col-sm-4 col-md-3'">
                <Spinner v-if="loading" />
                <template v-else>
                    <TypesIcons
                        v-if="wallet && wallet.types && wallet.types.length > 0"
                        :types="typesFiltered"
                        position="left"
                        :small="smallIcons"
                    />
                    <PaymentMethodIcon v-else :paymentMethod="paymentMethod.payment_method" :small="smallIcons" />
                </template>
            </div>
            <div class="col-12" :class="smallIcons ? 'col-sm-10' : 'col-sm-8 col-md-9'">
                <div>
                    <Spinner v-if="loading" />
                    <template v-else>
                        <span v-if="paymentMethod.amount">{{ paymentMethod.amount | currency }} with </span>
                        <span v-if="!!walletTitle">{{ walletTitle }}</span>
                        <PaymentMethodTitle v-else :paymentMethod="paymentMethod.payment_method" />
                    </template>
                </div>
                <div class="text-xxs text-muted">
                    <ul class="list-unstyled m-0">
                        <li v-if="sourceName">Funded by {{ sourceName }}</li>
                        <li>
                            <span v-if="paymentMethod.payment_method.expiration_date"
                                >Expires
                                {{ paymentMethod.payment_method.expiration_date | formatDate('DD/MM/YYYY') }}</span
                            ><span v-else>No expiration date</span>
                        </li>
                        <li v-if="paymentMethod.payment_method.balance">
                            Balance of {{ paymentMethod.payment_method.balance | currency }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PaymentMethodIcon from '@/components/PaymentMethods/PaymentMethod/Icon'
    import PaymentMethodTitle from '@/components/PaymentMethods/PaymentMethod/Title'
    import TypesIcons from '@/components/Types/Icons.vue'
    import { Spinner } from '@tech_hexeko/design-system'
    import { getTypesArrayFromWallet } from '@/utils/query'

    export default {
        components: {
            PaymentMethodIcon,
            PaymentMethodTitle,
            TypesIcons,
            Spinner,
        },
        data() {
            return {
                loading: false,
                wallet: null,
                walletTitle: null,
                sourceName: null,
            }
        },
        props: {
            clickable: {
                type: Boolean,
                default: true,
            },
            paymentMethod: {
                type: Object,
                required: true,
            },
            smallIcons: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            typesFiltered() {
                return getTypesArrayFromWallet(this.wallet)
            },
        },
        methods: {
            click(paymentMethod) {
                if (this.clickable) {
                    this.openPanel('PaymentMethodPanel', paymentMethod.id)
                }
            },
        },
        mounted() {
            if (
                this.paymentMethod &&
                this.paymentMethod.payment_method &&
                this.paymentMethod.payment_method.driver === 'wallet'
            ) {
                this.loading = true
                this.$api.paymentMethods
                    .show(this.paymentMethod.payment_method.id, { includes: ['source.types.slugs'] })
                    .then((method) => {
                        this.sourceName = method.source.source.name
                        this.walletTitle = method.source
                            ? method.source.types.map((type) => type.name[this.$i18n.locale]).join(', ')
                            : 'Wallet'
                        this.wallet = method.source ? method.source : { types: [{ technical_name: 'void' }] }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
    }
</script>

<style type="text/css">
    .payment-method-summary + .payment-method-summary {
        padding-top: 1rem;
    }
</style>
