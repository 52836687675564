<template>
    <FormRoot :loading="dataLoading">
        <!-- MIDs -->
        <Datatable name="MID's" searchClass="d-none" :loading="loading" :hover="false">
            <template v-slot:thead>
                <tr>
                    <th>MIDs</th>
                    <th>Created at</th>
                    <th>Updated at</th>
                    <th v-if="hasPermission">Actions</th>
                </tr>
            </template>
            <!-- Data -->
            <tr v-for="(mid, index) in form.mids" :key="index">
                <td>
                    <Input
                        v-model="form.mids[index]"
                        placeholder="Merchant ID"
                        class="border"
                        small
                        :readonly="!hasPermission"
                    />
                </td>
                <td class="align-middle">
                    <template v-if="originalMids[index] && originalMids[index].created_at">{{
                        originalMids[index].created_at | formatDate
                    }}</template>
                </td>
                <td class="align-middle">
                    <template v-if="originalMids[index] && originalMids[index].updated_at">{{
                        originalMids[index].updated_at | formatDate
                    }}</template>
                </td>
                <td v-if="hasPermission" class="text-center align-middle">
                    <a
                        href="#"
                        is="confirm"
                        tag="a"
                        class="text-danger"
                        @confirm="removeMid(index)"
                        title="Are you sure you want to delete this MID?"
                    >
                        <Icon icon="trash-alt" />
                    </a>
                </td>
            </tr>
            <!-- Actions -->
            <template v-slot:actions v-if="hasPermission">
                <Button @click="addMid" class="me-1" color="secondary"><Icon icon="plus" /> Add MID</Button>
            </template>
        </Datatable>

        <!-- Countries -->
        <Card title="Countries" class="mt-4" noPadding>
            <Alert icon="check" color="success" class="mb-2">
                <span v-if="form.countries.length === 0">All countries allowed if none checked</span>
                <span v-else>{{ implodedCountries }} allowed</span>
            </Alert>
            <Checkbox
                v-model="form.countries"
                :modelValue="country"
                v-for="country in countries"
                :key="country"
                :class="['mb-1', { 'pe-none': !hasPermission }]"
                >{{ country }}</Checkbox
            >
        </Card>

        <div v-if="hasPermission" class="mt-2">
            <Submit :disabled="form.loading" @click="onSubmit">Save</Submit>
        </div>
    </FormRoot>
</template>

<script>
    import { FormRoot, Card, Input, Submit, Button, Alert, Checkbox, Datatable } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    let _ = require('lodash')

    export default {
        components: {
            FormRoot,
            Card,
            Input,
            Submit,
            Button,
            Alert,
            Checkbox,
            Datatable,
        },
        mixins: [Forms],
        data() {
            return {
                countries: ['BE', 'FR'],
                fields: {
                    mids: [],
                    countries: [],
                },
                loading: true,
                messageSuccess: 'The issuing settings have been saved',
                originalMids: null,
            }
        },
        computed: {
            hasPermission() {
                return this.$store.getters.userHasPermission('managePartners')
            },
            implodedCountries() {
                return _.join(this.form.countries, ', ')
            },
        },
        methods: {
            addMid() {
                this.form.mids.push(null)
            },
            loadData() {
                this.loading = true
                return this.$api.partners
                    .show(this.id, { includes: ['countries', 'mids'] })
                    .then((partner) => {
                        this.originalMids = partner.mids ? partner.mids : null
                        const mids = this.originalMids ? this.originalMids.map((originalMid) => originalMid.mid) : []
                        return {
                            mids,
                            countries: partner.countries ? partner.countries : [],
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            removeMid(index) {
                this.form.mids.splice(index, 1)
                this.originalMids.splice(index, 1)
            },
            submit() {
                return this.$api.partners.update(this.id, this.$data.form.data()).finally(() => {
                    this.loadData()
                })
            },
        },
    }
</script>
