<template>
    <FormRoot>
        <template>
            <!-- start Date -->
            <Field :form.sync="form" type="date" name="start_date" hint="Start of validity of the balance"></Field>

            <!-- end Date -->
            <Field
                :form.sync="form"
                type="date"
                name="end_date"
                hint="End of validity of the balance"
                class="mt-3"
            ></Field>

            <!-- Button -->
            <FormActions>
                <Submit class="float-right" :disabled="!form.submittable" @click="onSubmit">Update dates</Submit>
            </FormActions>
        </template>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            quote: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                fields: {
                    start_date: null,
                    end_date: null,
                },
                messageSuccess: 'The dates have been updated',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            submit() {
                return this.$api.billing.quotes.updateDates(this.quote, {
                    ...this.$data.form.notNullData(),
                    user_id: this.$store?.getters?.getUser?.id ? this.$store.getters.getUser.id : undefined,
                })
            },
        },
    }
</script>
