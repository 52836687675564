<template>
    <span>{{ title }}</span>
</template>

<script>
    export default {
        name: 'PaymentMethodTitle',
        props: {
            paymentMethod: {
                type: Object,
                required: true,
            },
        },
        computed: {
            title: function () {
                // Bancontact
                if (this.isBancontact) {
                    return 'Bancontact'
                } else if (this.isCreditCard) {
                    return this.$t('paymentMethod.creditCard')
                }

                // Wallet
                else {
                    // Wallet source
                    let walletSource = this.$t('paymentMethod.wallet')

                    if (this.paymentMethod.identifier === 'ecv') {
                        walletSource = this.$t('paymentMethod.ecv')
                    } else if (this.paymentMethod.identifier === 'gift') {
                        walletSource = this.$t('paymentMethod.gift')
                    }

                    // String
                    return this.$t('paymentMethod.balance', {
                        source: walletSource,
                    })
                }
            },
            isBancontact: function () {
                return this.paymentMethod.brand === 'bancontact'
            },
            isCreditCard: function () {
                return !!(this.paymentMethod.brand && this.paymentMethod.brand !== 'bancontact')
            },
        },
    }
</script>
