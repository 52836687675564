<template>
    <Card v-if="wallet && wallet.source">
        <div class="row">
            <div class="col-12">
                <div class="float-md-right float-md-end mb-1 mb-md-0 text-md">
                    <span class="text-primary"
                        >{{ amount }}&nbsp;&euro;<span class="font-weight-light text-xs">
                            / {{ total }}&nbsp;&euro;</span
                        ></span
                    >
                </div>
                <div>
                    <TypesIcons class="mt-1" :types="getTypesArrayFromWallet(wallet.source)" position="left" />
                    <h5 class="fs-6 fw-normal mt-1 mb-0">
                        <span v-if="walletName">{{ walletName }}</span>
                        <code v-if="wallet.source.id" class="text-muted"
                            ><small> (ID {{ wallet.source.id }})</small></code
                        >
                    </h5>
                </div>
                <div class="text-xs text-muted">
                    <div v-if="wallet.source.source && wallet.source.source.name" class="mt-1">
                        <Icon :icon="wallet.source.source_type === 'user' ? 'user' : 'building'" />
                        {{ $t('user-account.wallet.fundedBy') }} {{ wallet.source.source.name }}
                    </div>
                    <div class="mt-1" v-for="(balance, index) in balances" :key="index">
                        <Icon icon="clock" />
                        <template v-if="balances.length > 1">
                            {{ balance.balance }}&nbsp;&euro; {{ $t('user-account.wallet.expiresMultiple') }}
                            {{ balance.end_date | formatDate('DD/MM/YYYY') }}
                        </template>
                        <template v-else>
                            {{ $t('user-account.wallet.expires') }} {{ balance.end_date | formatDate('DD/MM/YYYY') }}
                        </template>
                    </div>
                    <Progress
                        v-if="wallet.source.initial_spendable_amount && !useBalance"
                        class="mt-1"
                        :total="wallet.source.initial_spendable_amount"
                        :count="totalSpent"
                        :label="' '"
                    />
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
    import { Progress } from '@tech_hexeko/design-system'
    import Card from '@tech_hexeko/hexeko-user-account/src/components/Card'
    import TypesIcons from '@/components/Types/Icons'
    import { getTypesArrayFromWallet } from '@/utils/query'
    let _ = require('lodash')

    export default {
        name: 'wallet',
        components: {
            Card,
            Progress,
            TypesIcons,
        },
        data() {
            return {
                loading: null,
            }
        },
        props: {
            useBalance: {
                type: Boolean,
                default: false,
            },
            wallet: {
                type: Object,
                required: true,
            },
        },
        computed: {
            amount() {
                return this.wallet.amount_selectable && !this.useBalance
                    ? this.wallet.amount_selectable
                    : this.wallet.balance
            },
            balances() {
                if (!this.wallet.source.balances) return []
                return this.wallet.source.balances.filter((b) => {
                    return b.balance > 0 && new Date(b.end_date) > new Date()
                })
            },
            total() {
                return this.wallet.source.initial_spendable_amount && !this.useBalance
                    ? this.wallet.source.initial_spendable_amount
                    : this.wallet.source.total_deposit
            },
            totalSpent() {
                return _.round(this.wallet.source.initial_spendable_amount - this.wallet.source.amount_selectable, 2)
            },
            walletName() {
                if (this.wallet.source.types && this.wallet.source.types.length > 0) {
                    return _(this.wallet.source.types)
                        .map('name.' + this.$i18n.locale)
                        .join(', ')
                }
                return 'Wallet'
            },
        },
        methods: {
            getTypesArrayFromWallet,
        },
    }
</script>
