<template>
    <div>
        <Spinner v-if="loading" />
        <template v-else>
            <!-- Status -->
            <Alert color="warning" v-if="!can_switch.status" icon="exclamation-circle">
                <Reason :slug="can_switch.reason" />
            </Alert>

            <!-- Payment methods -->
            <PaymentMethodsList
                v-if="hasPaymentMethods"
                v-model="payment_methods_to_debit"
                :paymentMethods="allowedPaymentMethods.payment_methods"
                :amount="allowedPaymentMethods.amount_to_select"
            />
            <Alert v-else color="warning" icon="exclamation-circle">No payment methods available</Alert>

            <!-- Force switch -->
            <div class="d-flex flex-column align-items-start justify-content-start">
                <Checkbox v-model="forceSwitch" small>Do you want to force the switch?</Checkbox>
                <span class="text-muted">
                    You are responsible for any potential impact on third-party systems when enabling this option.
                </span>
            </div>

            <!-- Actions -->
            <template v-if="hasPaymentMethods">
                <Button
                    v-if="forceSwitch"
                    class="mt-2 float-right"
                    is="confirm"
                    @confirm="submit"
                    title="Are you sure you want to force the switch?"
                    :disabled="disabledSubmit || loadingSubmit"
                >
                    Force switch
                </Button>
                <Button v-else class="mt-2 float-right" @click="submit" :disabled="disabledSubmit || loadingSubmit">
                    Switch
                </Button>
            </template>
        </template>
    </div>
</template>

<script>
    import { Button, Alert, Spinner, Checkbox, Alerts } from '@tech_hexeko/design-system'
    import Reason from '@/components/Reason'
    import PaymentMethodsList from '@/components/PaymentMethods/List'
    import { formatBoolean } from '@/utils/query'

    export default {
        name: 'SwitchPaymentMethods',
        components: {
            Alert,
            Button,
            Checkbox,
            PaymentMethodsList,
            Reason,
            Spinner,
        },
        props: {
            id: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                payment_methods_to_debit: [],
                can_switch: null,
                allowedPaymentMethods: null,
                loading: true,
                forceSwitch: false,
                loadingSubmit: false,
            }
        },
        computed: {
            disabledSubmit() {
                return this.payment_methods_to_debit.length === 0
            },
            hasPaymentMethods() {
                return (
                    this.allowedPaymentMethods &&
                    this.allowedPaymentMethods.payment_methods &&
                    this.allowedPaymentMethods.payment_methods.length > 0
                )
            },
        },
        methods: {
            async fetchData() {
                this.loading = true
                try {
                    const requestStatus = await this.$api.cards.showCardTransactions(this.id, {
                        includes: ['can_switch_payment_methods'],
                    })

                    this.can_switch = requestStatus.can_switch_payment_methods

                    this.allowedPaymentMethods = await this.$api.cards.getPaymentMethodsAllowed(this.id, {
                        forced: formatBoolean(this.forceSwitch),
                        includes: [
                            'payment_methods.source.types.slugs',
                            'payment_methods.source.total_deposit',
                            'payment_methods.source.spendable_amount',
                        ],
                    })
                } catch (error) {
                    let errorTxt =
                        error && error.response && error.response.data
                            ? error.response.data.message
                            : 'An error occurred while trying to fetch payment methods'
                    Alerts.notificationError(errorTxt)
                } finally {
                    this.loading = false
                }
            },
            submit() {
                this.loadingSubmit = true
                return this.$api.cards
                    .switchPaymentMethods(this.id, {
                        payment_methods_to_debit: this.payment_methods_to_debit,
                        forced: formatBoolean(this.forceSwitch),
                    })
                    .then(() => {
                        this.submitted()
                        Alerts.notificationSuccess(this.forceSwitch ? 'Forced switch requested' : 'Switch requested')
                    })
                    .catch(() => {
                        Alerts.notificationError('Failed to switch')
                    })
                    .finally(() => {
                        this.loadingSubmit = false
                    })
            },
            submitted() {
                this.$emit('submitted')
            },
        },
        watch: {
            forceSwitch: {
                immediate: true,
                handler() {
                    this.fetchData()
                },
            },
        },
    }
</script>
