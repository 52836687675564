<template>
    <Datatable
        name="Pending Quote Lines"
        :pagination.sync="pagination"
        :loading="loading"
        @search="fetchData"
        :hideActions="hideActions"
        :hover="false"
    >
        <template v-slot:actions v-if="$store.getters.userHasPermission('manageQuotes')">
            <SelectAllButton
                :metaSelected="metaSelected"
                @selectAll="selectAll"
                @unselectAll="unselectAll"
                @reset="reset"
            >
                <DropdownItem icon="trash-alt" @click="bulkDelete" :disabled="metaSelected.noneSelected">
                    Delete
                </DropdownItem>
            </SelectAllButton>
        </template>
        <template v-slot:thead>
            <tr>
                <th v-if="$store.getters.userHasPermission('manageBillingAccounts')"></th>
                <th>Date</th>
                <th>Type</th>
                <th>Quotable ID</th>
                <th width="100px">User</th>
                <th>Failed</th>
            </tr>
        </template>
        <tr v-for="(line, index) in pendingQuoteLines" :key="'pendingQuoteLine-' + index">
            <td @click.stop="toggleSelect(line)" v-if="$store.getters.userHasPermission('manageBillingAccounts')">
                <ToggleCheckbox :value="isSelected(line)" />
            </td>
            <td>{{ line.created_at | formatDate }}</td>
            <td>{{ line.type }}</td>
            <td>{{ line.quotable_id }}</td>
            <td>
                <a v-if="line.user_id" href="#" @click.prevent="openUser(line.user_id)"
                    ><Icon icon="user" /> {{ line.user_id }}</a
                >
            </td>
            <td>
                <span v-if="line.failed_at" v-tooltip="line.failed_reason">{{ line.failed_at | formatDate }}</span>
                <template v-else>-</template>
            </td>
        </tr>
    </Datatable>
</template>

<script>
    import { Alerts, Datatable, ToggleCheckbox, DropdownItem } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import SelectAll from '@/mixins/SelectAll'
    import SelectAllButton from '@/components/layout/SelectAllButton'

    export default {
        name: 'PendingQuoteLines',
        mixins: [SelectAll],
        components: {
            Datatable,
            SelectAllButton,
            ToggleCheckbox,
            DropdownItem,
        },
        props: {
            billingAccount: {
                type: String,
                required: true,
            },
            hideActions: Boolean,
            params: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                loading: true,
                pagination: { current_page: 1 },
                pendingQuoteLines: [],
                search: null,
            }
        },
        methods: {
            deletePendingQuoteLine(id) {
                this.$api.billing.billingAccounts
                    .deletePendingQuoteLine(id)
                    .then(() => {
                        Alerts.messageSuccess('Pending Quote Line deleted')
                        this.fetchData()
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
            },
            fetchData(search) {
                if (!this.billingAccount) return
                this.search = search
                this.loading = true
                this.$api.billing.billingAccounts
                    .getPendingQuoteLines(
                        this.billingAccount,
                        this.mergeParams({
                            page: this.pagination.current_page,
                            search: search ? search : undefined,
                        })
                    )
                    .then((response) => {
                        this.pendingQuoteLines = response.data
                        this.pagination = response.meta.pagination
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            openUser(id) {
                this.openPanel('userPanel', Number(id))
            },
            allIds() {
                return this.$options.filters.pluck(this.pendingQuoteLines, 'id')
            },
            bulkDelete() {
                this.bulkAction('Delete', (id) => {
                    return this.$api.billing.billingAccounts.deletePendingQuoteLine(id)
                }).then(() => {
                    this.unselectAll()
                    this.pagination = { current_page: 1 }
                    this.fetchData(this.search)
                })
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
