<template>
    <Card v-if="paymentMethod" class="p-4 position-relative">
        <div
            v-if="paymentMethod.availability && !paymentMethod.availability.status"
            class="alert alert-light bg-light text-xxs text-muted content-center mb-0 w-100 h-100 d-flex justify-content-center align-items-center shadow-sm z-index-1"
        >
            <div class="row align-items-center">
                <div class="col-md-2 mb-2 mb-md-0 text-right">
                    <PaymentMethodIcon :paymentMethod="paymentMethod" width="50px" height="50px" />
                </div>
                <div class="col-md-10">
                    <template v-if="$te(`paymentMethod.availability.${paymentMethod.availability.reason}`)">
                        {{ $t(`paymentMethod.availability.${paymentMethod.availability.reason}`) }}
                        <template
                            v-if="
                                paymentMethod.availability.reason === 'payment_unauthorized_by_supplier' &&
                                paymentMethod.driver === 'monizze'
                            "
                            >{{ $t('paymentMethod.availability.monizze_unauthorized_explanation') }}</template
                        >
                    </template>
                    <template v-else>
                        {{ paymentMethod.availability.reason }}
                    </template>
                </div>
            </div>
        </div>

        <div class="row">
            <!-- Icon -->
            <div class="col-md-2 mb-2 mb-md-0">
                <PaymentMethodIcon :paymentMethod="paymentMethod" width="50px" height="50px" />
            </div>

            <!-- Text -->
            <div class="col-md-10">
                <!-- Title -->
                <PaymentMethodTitle :paymentMethod="paymentMethod" class="py-1 py-md-0" />

                <div class="text-xs text-muted">
                    <!-- Description -->
                    <PaymentMethodDescription :paymentMethod="paymentMethod" />

                    <span v-if="paymentMethod.expiration_date"> - </span>

                    <!-- Expiration date -->
                    <span v-if="paymentMethod.expiration_date">
                        {{ $t('creditCard.expires') }}
                        <strong>{{ paymentMethod.expiration_date | formatDate('DD/MM/YYYY') }}</strong>
                    </span>
                </div>
            </div>
        </div>

        <!-- Balance -->
        <div
            v-if="displayBalance"
            class="position-absolute is--top-right text-sm text-right text-primary mt-3 mr-3 me-3"
        >
            {{ paymentMethod.balance | numeral('0,0.00') }} €
        </div>

        <!-- Actions -->
        <div v-else-if="isRemovable && !hideActions" @click.stop class="position-absolute is--top-right mt-3 mr-3 me-3">
            <Spinner v-if="loading === paymentMethod.identifier" class="mt-2 me-2" />
            <Dropdown v-else color="white" position="right">
                <DropdownItem class="text-danger" icon="times" @click="deleteCard(paymentMethod.identifier)"
                    >&nbsp;{{ $t('creditCard.remove') }}</DropdownItem
                >
            </Dropdown>
        </div>
    </Card>
</template>

<script>
    import { Dropdown, DropdownItem, Spinner } from '@tech_hexeko/design-system'
    import Card from '@tech_hexeko/hexeko-user-account/src/components/Card'
    import PaymentMethodIcon from './Icon'
    import PaymentMethodTitle from './Title'
    import PaymentMethodDescription from './Description'

    export default {
        name: 'payment-method-item',
        data() {
            return {
                loading: null,
            }
        },
        components: {
            Dropdown,
            DropdownItem,
            Spinner,
            PaymentMethodIcon,
            PaymentMethodTitle,
            PaymentMethodDescription,
            Card,
        },
        props: {
            paymentMethod: Object,
            hideActions: Boolean,
        },
        computed: {
            isRemovable() {
                return this.paymentMethod.driver === 'stripe' && this.paymentMethod.identifier !== 'bancontact'
            },
            displayBalance() {
                return this.paymentMethod.balance !== undefined && this.paymentMethod.balance !== null
            },
        },
        methods: {
            deleteCard(id) {
                this.loading = id
                this.$api.me
                    .deleteCreditCard(id)
                    .then(() => {
                        this.$emit('deleted')
                    })
                    .catch((error) => console.log(error))
                    .finally(() => {
                        this.loading = null
                    })
            },
        },
    }
</script>

<style type="text/css" scoped>
    .is--top-right {
        top: 0;
        right: 0;
    }
</style>
