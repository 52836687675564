<template>
    <Panel
        :title="account ? account.name : '...'"
        :loading="loading"
        @closePanel="$emit('closePanel', { refresh: false })"
    >
        <Tabs v-if="account">
            <!-- Billing Account -->
            <Tab name="Details" :selected="true">
                <Sheet name="Details" class="mb-2">
                    <Row name="Name">{{ account.name }}</Row>
                    <Row name="Address">{{ account.address }}</Row>
                    <Row name="City">{{ account.zipcode }} {{ account.city }} {{ account.country_iso }}</Row>
                    <Row name="VAT">{{ account.vat }}</Row>
                    <Row name="Language">{{ account.language_iso }}</Row>
                    <Row name="E-mail">{{ account.email }}</Row>
                    <Row name="Created at">{{ account.created_at | formatDate }}</Row>
                    <Row name="Updated at">{{ account.updated_at | formatDate }}</Row>
                    <Row name="Direct invoices process"><Boolean :value="account.direct_invoices_process" /></Row>
                    <Row name="Auto-finalize invoices"><Boolean :value="account.auto_finalize" /></Row>
                    <Row v-if="!!relatedGroups" name="Related groups">
                        <template v-for="(group, key) in relatedGroups">
                            <template v-if="key > 0">, </template>
                            <a href="#" @click.prevent="openGroup(group)">{{ group.name }}</a>
                        </template>
                    </Row>
                    <Row name="Default order reference">{{ account.default_order_reference }}</Row>
                    <template v-if="!!paymentMethods">
                        <template v-for="paymentMethod in paymentMethods">
                            <Row v-if="paymentMethod.type === 'sepa_debit'" name="SEPA activated at">
                                {{ paymentMethod.created_at | formatDate }}
                                <span class="text-muted">
                                    (•••• {{ paymentMethod.metadata.iban.slice(-4)
                                    }}<template v-if="paymentMethod.holder_name"
                                        >, {{ paymentMethod.holder_name }}</template
                                    >)
                                </span>
                            </Row>
                        </template>
                    </template>
                </Sheet>

                <ID name="Hexeko" :value="account.id" />
            </Tab>

            <!-- Quotes -->
            <Tab name="Quotes" v-if="$store.getters.userHasPermission('viewAnyQuotes')">
                <Quotes :params="{ billing_account_id: account.id }"></Quotes>
            </Tab>

            <!-- Invoices -->
            <Tab name="Invoices" v-if="$store.getters.userHasPermission('viewAnyInvoices')">
                <Invoices :params="{ billing_account_id: account.id }"></Invoices>
            </Tab>

            <!-- Credit Notes -->
            <Tab name="Credit Notes" v-if="$store.getters.userHasPermission('viewAnyInvoices')">
                <CreditNotes :params="{ billing_account_id: account.id }"></CreditNotes>
            </Tab>

            <!-- Products -->
            <Tab name="Products" v-if="$store.getters.userHasPermission('viewAnyProducts')">
                <Products :billingAccount="account.id"></Products>
            </Tab>

            <!-- Pending Quote Lines -->
            <Tab name="Pending Quote Lines" v-if="$store.getters.userHasPermission('viewAnyQuotes')">
                <PendingQuoteLines :billingAccount="account.id" />
            </Tab>
        </Tabs>

        <!-- Actions -->
        <template v-slot:actions v-if="$store.getters.userHasPermission('manageBillingAccounts')">
            <!-- Dropdown -->
            <Dropdown small title="Actions" position="right">
                <DropdownItem @click="openModal('updateBillingAccount')" icon="user-edit"
                    >Update billing account</DropdownItem
                >
                <DropdownItem confirm @confirm="deleteBillingAccount" icon="trash-alt" class="text-danger"
                    >Delete</DropdownItem
                >
            </Dropdown>

            <!-- Update user -->
            <Modal
                v-if="isModalOpen('updateBillingAccount')"
                @close="closeModal('updateBillingAccount')"
                title="Update billing account"
            >
                <UpdateBillingAccount :id="account.id" @submitted="billingAccountUpdated" />
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import {
        Panel,
        Tabs,
        Tab,
        Sheet,
        Row,
        ID,
        Dropdown,
        DropdownItem,
        Boolean,
        Modal,
    } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import Products from '@/components/Products'
    import Quotes from '@/components/Quotes'
    import Invoices from '@/components/Invoices'
    import CreditNotes from '@/components/CreditNotes'
    import PendingQuoteLines from '@/components/PendingQuoteLines'
    import UpdateBillingAccount from '@/components/BillingAccounts/UpdateBillingAccount'

    export default {
        name: 'BillingAccountPanel',
        components: {
            Quotes,
            Panel,
            Tabs,
            Tab,
            Sheet,
            Row,
            ID,
            Dropdown,
            DropdownItem,
            Boolean,
            Modal,
            Products,
            Invoices,
            PendingQuoteLines,
            UpdateBillingAccount,
            CreditNotes,
        },
        mixins: [Modals],
        props: {
            id: String,
        },
        data() {
            return {
                account: null,
                loading: true,
                paymentMethods: null,
                relatedGroups: null,
            }
        },
        methods: {
            fetchData() {
                this.loading = true
                this.$api.billing.billingAccounts.show(this.id).then((account) => {
                    this.account = account
                    this.$api.groups
                        .get({ billing_account_id: account.id })
                        .then((groups) => {
                            this.relatedGroups = groups.data
                        })
                        .finally(() => {
                            // stop loading
                            this.loading = false
                        })
                })
                this.$api.billing.billingAccounts.getPaymentMethods(this.id).then((response) => {
                    this.paymentMethods = response.data
                })
            },
            deleteBillingAccount() {
                this.$api.billing.billingAccounts.delete(this.id).then(() => {
                    this.closePanel({ refresh: true })
                })
            },
            closePanel(args) {
                this.$emit('closePanel', args)
            },
            openGroup(group) {
                this.openPanel('GroupPanel', group.id)
            },
            billingAccountUpdated() {
                this.fetchData()
                this.closeModal('updateBillingAccount')
                this.closeModal('permissions')
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
